import { USER_PERMISSION } from './../../../utility/constants';
import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  EmployeeNamespace,
  EmployeeGetter,
  EmployeeAction
} from '@/store/employee/employee.module-types';
import {
  EmployeeViewModel,
  EditEmployeeInput,
  CreateEmployeeInput
} from '@/api/api';
import { dispatchEmployeeAction } from '@/store/employee/employee.dispatch';
import { employeeListDispatcher } from '../employeeList/store/employee-list.module';
import ApiClientFactory from '@/api/apiClientFactory';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import router from '@/router';
import { enumStringValueToDropdownOptions } from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import vSelect from 'vue-select';

const pattern =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_^])[A-Za-z\\d@$!%*?&_^]{6,}$';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    vSelect
  }
})
export default class EmployeeForm extends Vue {
  @EmployeeNamespace.Getter(EmployeeGetter.employee)
  employee!: EmployeeViewModel;

  input = this.getDefaultInputValue();

  employeeId = '';
  passwordRules = {} as any | '';
  error = '';
  userPermissions: DropdownOption[] = enumStringValueToDropdownOptions(
    USER_PERMISSION
  ).map((s) => new DropdownOption(s.value, this.$t(s.text).toString()));

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
  };

  mounted() {
    this.employeeId = this.$route.params.id;
  }

  @Watch('employeeId')
  getEmployeeById(): void {
    if (this.employeeId) {
      dispatchEmployeeAction(EmployeeAction.loadEmployee, this.employeeId);
    }
  }

  @Watch('employee')
  employeeUpdated(): void {
    if (this.employee) {
      this.input = {
        userName: this.employee.userName as string,
        fullName: this.employee.fullName as string,
        password: '',
        phoneNumber: this.employee.phoneNumber as string,
        permissions: this.employee.permissions ?? []
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  @Watch('employeeId')
  setPasswordRules() {
    if (this.employeeId) {
      this.passwordRules = '';
    } else {
      this.passwordRules = {
        required: true,
        regex: pattern
      };
    }
  }

  @Watch('input.password')
  passwordUpdated() {
    if (this.employeeId) {
      this.passwordRules = this.input.password
        ? {
            regex: pattern
          }
        : '';
    }
  }

  getDefaultInputValue() {
    return {
      fullName: '',
      userName: '',
      password: '',
      phoneNumber: '',
      permissions: [] as string[]
    };
  }

  async add(): Promise<void> {
    const client = new ApiClientFactory().employeeClient();

    const res = await client.create(this.input as CreateEmployeeInput);

    if (res!.status === 200) {
      const id = await res!.data.text();

      if (id) {
        this.error = this.$t('ExistingUserNameError').toString();
      } else {
        employeeListDispatcher.load();
        // show toast
        this.$bvToast.toast(this.$t('CreateEmployeeSuccessfully').toString(), {
          title: this.$t('Employee').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });

        router.push({ path: '/employee-list' });
      }
    }
  }

  edit() {
    const client = new ApiClientFactory().employeeClient();

    client
      .edit(
        this.employeeId,
        new EditEmployeeInput({
          userName: this.input.userName,
          fullName: this.input.fullName,
          password: this.input.password,
          phoneNumber: this.input.phoneNumber,
          permissions: this.input.permissions
        })
      )
      .then((result) => {
        if (result && result.succeeded) {
          employeeListDispatcher.load();
          // show toast
          this.$bvToast.toast(
            this.$t('UpdateEmployeeSuccessfully').toString(),
            {
              title: this.$t('Employee').toString(),
              toaster: 'b-toaster-bottom-right',
              variant: 'success'
            }
          );
          return;
        }

        const errMessage = result?.errors
          ? result?.errors[0]?.description
          : this.$t('UpdateEmployeeFail').toString();

        if (errMessage?.includes('Username')) {
          this.$refs.formRules.setErrors({
            userName: [errMessage]
          });
        }

        this.$bvToast.toast(
          errMessage ?? this.$t('UpdateEmployeeFail').toString(),
          {
            title: this.$t('Employee').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          }
        );
      });
  }

  submit(): void {
    this.$refs.formRules.validate().then(async (success) => {
      if (success) {
        if (this.employeeId) {
          this.edit();
        } else {
          await this.add();
        }
      }
    });
  }
}
